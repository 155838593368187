`
<template>
  <div class="user">
    <Header ref="Header"></Header>
    <div class="info">
      <div class="avatar" @click="openavatar">
        <img :src="userInfo.imgUrl">
      </div>
      <div class="name">笔名：{{ userInfo.Name }}</div>
      <div class="id">账号：<span v-if="userInfo.PhoneMobile ">{{ userInfo.PhoneMobile }}</span><span
          v-else> {{ userInfo.Email }}</span></div>
      <div class="grade">年级：{{ userInfo.GotoTime }}</div>
      <div class="popularity">人气值：{{ userInfo.Collect }}</div>
      <div class="quit" @click="quit">退出</div>
    </div>
    <div class="nav">
      <div class="block" v-bind:class="{ set: start=='作品' }" @click="openwork">我的作品</div>
      <div class="block" v-bind:class="{ set: start=='点赞' }" @click="openlike">我的点赞</div>
      <div class="block" v-bind:class="{ set: start=='收到赞' }" @click="openreceive">我的消息</div>
      <div class="block" v-bind:class="{ set: start=='评论' }" @click="opencomment">我的弹幕</div>
    </div>
    <div class="in"></div>
    <div v-if="data.length>=1">
      <div class="data" v-bind:style="'height:'+height" v-if="start == '作品'||start == '点赞'" @scroll="scrollEvent">
        <div class="block" v-for="(item,index) in data" :key="index"
             @click="openDetails(item.SidNumber)">
          <div class="img"><img :src="item.imageUrl"></div>
          <p class="SidNumber">编号：{{ item.SidNumber }}</p>
          <p class="penName">标题：{{ item.penName }}</p>
          <p v-if="start == '作品'||start == '点赞'" class="collect"><i></i>{{ item.collect }}</p>
          <p class="time" v-if="start == '作品'||start == '点赞'">时间：{{ item.time }}</p>
        </div>
      </div>

      <div v-if="start == '收到赞'" class="datas" v-bind:style="'height:'+height">
        <div v-for="(item,index) in data" :key="index">
          <div class="block" v-if=" item.Start=='收到赞'">
            <div class="ico"><img :src="item.avatar"></div>
            <div class="name">笔名：{{ item.names }}</div>
            <div class="time">时间：{{ item.time }}</div>
            <div class="text">赞了这副画作</div>
            <div class="bc">
              <img :src="item.imageUrl">
              <div class="titles">标题：{{ item.penName }}</div>
              <div class="number">编号：{{ item.SidNumber }}</div>
            </div>
          </div>
          <div v-else-if="item.Start=='中奖信息'" class="block Prize" @click="openPrize(item.Image)">
            <div class="left"></div>
            <div class="text">恭喜您中奖了！请截图本页，发送截图、</div>
            <div class="text1">万人添画注册手机号、支付宝收款账号</div>
            <div class="text2">及账户名至“3Q Lab”公众号领取奖励！</div>
            <div class="number">中奖序号：{{ item.Number }}</div>
            <div class="Money">金额：{{ item.Money }}元</div>
            <img :src="item.Image">
            <div class="time">中奖时间：{{ item.Time }}</div>
          </div>
          <div class="Evaluate" v-if=" item.Start=='被评论'">
            <div class="name">笔名：{{ item.name }}</div>
            <div class="time">时间：{{ item.time }}</div>
            <div class="text">评论了这副画作</div>
            <p>{{ item.content }}</p>
          </div>
        </div>
      </div>

      <div v-if="start == '评论'" class="critique" v-bind:style="'height:'+height">
        <Comment v-bind:style="'display: none;line-height:'+height"></Comment>
        <div class="block" v-for="(item,index) in data" :key="index">
          <div class="time">时间：{{ item.times }}</div>
          <div class="cout">{{ item.comment }}</div>
          <div class="close" @click="commentclose(item._id)"></div>
        </div>
      </div>
    </div>
    <div class="notdata" v-if="data.length==0&&start == '作品'"
         v-bind:style="'line-height:'+height+';height:'+(height*2)">暂无作品
    </div>
    <div class="notdata" v-if="data.length==0&&start == '点赞'" v-bind:style="'line-height:'+height">暂无点赞</div>
    <div class="notdata" v-if="data.length==0&&start == '收到赞'" v-bind:style="'line-height:'+height">暂无收到的赞</div>
    <div class="notdata" v-if="data.length==0&&start == '评论'" v-bind:style="'line-height:'+height">暂无弹幕</div>
    <load ref="load"></load>
    <Avatar ref="Avatar"></Avatar>
    <div class="detailed" v-if="detailed" @click="closeImg">
      <div class="main">
        <div class="img"><img :src="img"></div>
        <div class="great" v-if="start == '作品'"><i></i><span>{{ collect }}</span></div>
        <div class="step" v-bind:class="{steps: start=='点赞'}">作者：{{ name }}</div>
        <div class="time">{{ time }}</div>
      </div>
    </div>
    <Details ref="Details"></Details>
    <Prize ref="Prize"></Prize>
  </div>
</template>

<script>
import Header from "../components/Header";
import {Dialog, Notify, Toast} from 'vant'
import {initwork, initcollect, initreceive, initcomment, putavatar, rmcomment} from '../api/user'
import load from '../components/load'
import Avatar from '../components/Avatar'
import Comment from '../components/Comment'
import {setuser} from "../api/login";
import {fixed} from "../api/introduce";
import Details from '../components/Details'
import Prize from '../components/Prize'

export default {
  data() {
    return {
      detailed: false,
      height: '0px',
      start: '作品',
      userInfo: {},
      data: [],
      img: '',
      name: '',
      collect: '',
      time: '',
      titleNumber: '',
      clientWidth: document.documentElement.clientWidth,
      skip: 1,
      pageTol: 0,
    }
  },
  components: {
    Header,
    Avatar,
    Comment,
    load,
    Details,
    Prize
  },
  mounted() {
    let height = document.documentElement.clientHeight - 40 - 150 - 41 - 5 - 15
    this.height = height + 'px'

    fixed().then(res => {
      let num = res.num
      this.titleNumber = num.titleNumber
      this.init()
    })
  },
  methods: {
    openPrize(img) {
      this.$refs.Prize.openImg(img)
    },
    scrollEvent(e) {
      if (e.srcElement.scrollTop + e.srcElement.clientHeight == e.srcElement.scrollHeight) {
        if (this.start == '作品') {
          if (this.skip == this.pageTol) {
            Toast('已经到底了～');
            return
          }
          this.skip++
          this.findwork()
        }
        if (this.start == '点赞') {
          if (this.skip == this.pageTol) {
            Toast('已经到底了～');
            return
          }
          this.skip++
          this.findcollect()
        }

        if (this.start == '收到赞') {
          if (this.skip == this.pageTol) {
            Toast('已经到底了～');
            return
          }
          this.findreceive()
        }
      }
    },
    //打开详情
    openDetails(SidNumber) {
      this.$refs.Details.openImg(SidNumber)
    },
    openavatar() {
      this.$refs.Avatar.openView()
    },
    commentclose(_id) {
      Dialog.confirm({
        message: '是否删除该评论？',
      }).then(() => {
        this.$refs.load.open()
        rmcomment({_id}).then(res => {
          this.$refs.load.close()
          if (res.code == 0) {
            Notify({type: 'success', message: res.message});
            this.findcomment()
          } else {
            Notify({type: 'warning', message: res.message});
          }
        })
      }).catch(() => {
      });
    },
    selectImg(data) {
      let id = this.userInfo.id
      this.$refs.load.open()
      putavatar({data, id}).then(res => {
        let userInfo = res.userInfo
        this.userInfo = userInfo
        this.$store.state.userInfo = userInfo
        this.$refs.load.close()
        Notify({type: 'success', message: '更改头像成功'});
      })
    },
    openImg(str, name, collect, time) {
      this.detailed = true
      this.img = str
      this.name = name
      this.collect = collect
      this.time = time
    },
    closeImg() {
      this.detailed = false
    },
    findwork() {
      let titleNumber = this.titleNumber
      let skip = this.skip
      this.$refs.load.open()
      let _id = this.$store.state.userInfo._id
      initwork({_id, titleNumber, skip}).then(res => {
        this.$refs.load.close()
        if (res.code == 0) {
          let list = res.list

          for (let item in list) {
            list[item].time = list[item].times
            let penName = list[item].penName
            if (penName.length >= 5) {
              list[item].penName = penName.slice(0, 5) + '...'
            }
          }
          let number = res.number
          number = Math.ceil(number / 10)
          this.pageTol = number
          for (let i in list) {
            this.data.push(list[i])
          }
        } else {
          Notify({type: 'warning', message: res.message});
        }

      })
    },
    findcollect() {
      this.$refs.load.open()
      let skip = this.skip
      let _id = this.$store.state.userInfo._id
      let titleNumber = this.titleNumber
      initcollect({_id, titleNumber, skip}).then(res => {
        this.$refs.load.close()
        if (res.code == 0) {
          let list = res.list
          let number = res.number
          number = Math.ceil(number / 10)
          this.pageTol = number
          for (let i in list) {
            this.data.push(list[i])
          }
        } else {
          Notify({type: 'warning', message: res.message});
        }
      })
    },
    findreceive() {
      this.$refs.load.open()
      let _id = this.$store.state.userInfo._id
      let titleNumber = this.titleNumber
      initreceive({_id, titleNumber}).then(res => {
        this.$refs.load.close()
        if (res.code == 0) {
          let list = res.data
          let data = []

          for (let item in list) {
            let Start = list[item].Start
            if (Start == '收到赞') {
              let SidNumber = list[item].SidNumber
              if (SidNumber.length >= 6) {
                let text = SidNumber.slice(0, 5)
                text += '...'
                list[item].SidNumber = text
              }

              let names = list[item].names
              if (names.length >= 6) {
                let text = names.slice(0, 5)
                text += '...'
                list[item].names = text
              }

              let penName = list[item].penName
              if (penName.length >= 6) {
                let text = penName.slice(0, 5)
                text += '...'
                list[item].penName = text
              }
              data.push(list[item])
            } else {
              data.push(list[item])
            }


          }
          this.data = data
        } else {
          Notify({type: 'warning', message: res.message});
        }
      })
    },
    findcomment() {
      this.data = []
      let titleNumber = this.titleNumber
      let _id = this.$store.state.userInfo._id
      let skip = this.skip

      initcomment({_id, titleNumber, skip}).then(res => {
        if (res.code == 0) {
          let list = res.list
          let number = res.number
          number = Math.ceil(number / 10)
          this.pageTol = number
          if (this.data.length == 0) {
            this.data = list
          } else {
            for (let i in list) {
              this.data.push(list[i])
            }
          }
        } else {
          Notify({type: 'warning', message: res.message});
        }
      })
    },
    quit() {
      Dialog.confirm({
        message: '是否退出当前的账号？',
      }).then(() => {
        this.$store.state.userInfo = {}
        this.$router.push(
            {
              path: '/zju/login',
              query: {
                quit: 1
              }
            }
        )
      }).catch(() => {
      });
    },
    opencomment() {
      if (this.start !== '评论') {
        this.start = '评论'
        this.data = []
        this.findcomment()
      }
    },
    openwork() {
      if (this.start !== '作品') {
        this.start = '作品'
        this.data = []
        this.skip = 1
        this.findwork()
      }
    },
    openlike() {
      if (this.start !== '点赞') {
        this.start = '点赞'
        this.data = []
        this.skip = 1
        this.findcollect()
      }
    },
    openreceive() {
      if (this.start !== '收到赞') {
        this.start = '收到赞'
        this.data = []
        this.findreceive()
      }
    },
    init() {

      let userInfo = this.$store.state.userInfo

      let _id = userInfo._id
      setuser({_id}).then(res => {
        let userInfos = res.userInfo
        this.userInfo = userInfos
      })

      this.findwork()
    }
  }
}
</script>

<style scoped lang="scss">
.user {
  .detailed {
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 50;

    .main {
      width: 300px;
      height: 500px;
      background-color: #FFFFFF;
      position: absolute;
      left: 50%;
      top: 50%;
      margin-left: -150px;
      margin-top: -250px;


      .img {

        width: 270px;
        height: 400px;
        position: absolute;
        left: 15px;
        top: 10px;

        img {
          width: 100%;
          height: 100%;
        }
      }

      @mixin pos {
        position: absolute;
        font-size: 12px;
      }

      .time {
        position: absolute;
        left: 40px;
        bottom: 20px;
        font-size: 12px;
      }

      .great {
        @include pos;
        left: 40px;
        bottom: 50px;
        width: 100px;
        height: 20px;

        i {
          margin-right: 10px;
          display: inline-block;
          background-image: url("../assets/image/create/like.png");
          width: 15px;
          height: 15px;
          background-size: 100% 100%;
        }
      }


      .step {
        @include pos;
        left: 160px;
        bottom: 50px;
        width: 100px;
        height: 20px;

        i {
          margin-right: 10px;
          display: inline-block;
          background-image: url("../assets/image/create/step.png");
          width: 15px;
          height: 15px;
          background-size: 100% 100%;
        }
      }

      .steps {
        left: 40px;
      }
    }
  }

  .info {
    width: 100%;
    height: 150px;
    background-color: whitesmoke;
    position: relative;

    .avatar {
      position: absolute;
      width: 92px;
      height: 92px;
      background-color: #d8d8d8;
      border-radius: 50%;
      left: 40px;
      top: 30px;
      //background-image: url("../assets/image/login/avatar.png");
      //background-size: 100% 100%;

      .input-loc-img {
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        opacity: 0;
      }

      img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
      }
    }

    .quit {
      color: #973733;
      position: absolute;
      right: 20px;
      bottom: 20px;
      font-size: 12px;

    }

    @mixin txt {
      position: absolute;
      left: 150px;
      font-size: 13px;
      color: #666666;
    }

    .name {
      @include txt;
      top: 25px;
    }

    .id {
      @include txt;
      top: 55px;
    }

    .grade {
      @include txt;
      top: 83px;
    }

    .popularity {
      @include txt;
      top: 110px;
    }


  }

  .in {
    width: 100%;
    height: 5px;
    background-color: #f0f0f0;
  }

  .nav {
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: space-around;
    //border-bottom: 1px solid #efefef;
    .block {
      color: #020202;
      width: 80px;
      height: 40px;
      display: inline-block;
      line-height: 40px;
      font-size: 12px;
      text-align: center;
    }

    .set {
      border-bottom: 3px solid #973733;
    }
  }


  .data {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    overflow-y: scroll;
    justify-content: space-around;
    background-color: #f0f0f0;

    .block {
      width: 46%;
      height: 334px;
      margin-bottom: 5px;
      box-shadow: 0px 2px 7px 0px rgba(159, 159, 159, 0.5);
      background-color: #FFFFFF;
      position: relative;

      .img {
        position: absolute;
        width: 145px;
        height: 220px;
        border: 1px solid rgba(151, 55, 51, 0.2);
        left: 50%;
        margin-left: -72.5px;
        top: 10px;

        img {
          width: 100%;
          height: 100%;
        }
      }


      p {
        text-indent: 1rem;
        font-size: 11px;
        color: #020202;
      }

      i {
        margin-right: 10px;
        display: inline-block;
        background-image: url("../assets/image/create/like.png");
        width: 15px;
        height: 15px;
        background-size: 100% 100%;
      }

      .SidNumber {
        position: absolute;
        left: 50%;
        margin-left: -88px;
        top: 230px;
      }

      .penName {
        position: absolute;
        left: 50%;
        margin-left: -88px;
        top: 251px;
      }

      .time {
        position: absolute;
        left: 50%;
        margin-left: -88px;
        top: 272px;
      }

      .collect {
        position: absolute;
        left: 50%;
        margin-left: -88px;
        top: 298px;
      }
    }

    .blocks {
      width: 100%;
      height: 30px;
    }
  }

  .notdata {
    width: 100%;
    height: 100%;
    color: #898989;
    text-align: center;
    font-size: 14px;
  }

  .datas {
    background-color: #f0f0f0;
    overflow-y: scroll;

    .block {
      width: 95%;
      margin: 0 auto;
      line-height: 40px;
      font-size: 12px;
      background-color: #FFFFFF;
      height: 100px;
      margin-bottom: 10px;
      box-shadow: 0px 2px 7px 0px rgba(159, 159, 159, 0.5);
      position: relative;
      color: #bbbbbb;


      .ico {
        position: absolute;
        width: 50px;
        height: 50px;
        left: 8px;
        top: 25px;
        border-radius: 50px;
        overflow: hidden;
        background-color: #666666;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .name {
        position: absolute;
        left: 69px;
        top: 7px;
      }

      .time {
        position: absolute;
        left: 69px;
        top: 31px;
      }

      .text {
        position: absolute;
        left: 69px;
        top: 54px;
      }

      .bc {
        width: 149px;
        height: 85px;
        background-color: #f0f0f0;
        position: absolute;
        left: 200px;
        top: 7px;
        font-size: 12px;
        overflow: hidden;

        img {
          width: 45px;
          height: 100%;
          position: absolute;
          left: 0;
          top: 0;

        }

        .titles {
          position: absolute;
          left: 55px;
          top: 11px;
          width: auto;
          color: #202020;
        }

        .number {
          position: absolute;
          left: 55px;
          top: 40px;
          width: 86px;
          height: 40px;
          color: #202020;
          overflow: hidden;
          font-size: 12px;
        }
      }
    }

    .Prize {
      position: relative;
      font-size: 12px;

      .left {
        position: absolute;
        width: 10px;
        height: 100%;
        background-color: #973733;
      }

      img {
        position: absolute;
        width: 70px;
        left: 10px;
        top: 0;
        height: 100%;
        display: block;
      }

      .text {
        position: absolute;
        color: #8D8D8D;
        top: 5px;
        left: 95px;
        line-height: normal;
      }

      .text1 {
        position: absolute;
        color: #8D8D8D;
        top: 25px;
        left: 95px;
        line-height: normal;
      }

      .text2 {
        position: absolute;
        line-height: normal;
        color: #8D8D8D;
        top: 45px;
        left: 95px;
      }

      .number {
        position: absolute;
        line-height: normal;
        top: 63px;
        left: 95px;
        color: #973733;
      }

      .Money {
        position: absolute;
        line-height: normal;
        color: #973733;
        top: 80px;
        right: 5px;
      }

      .time {
        line-height: normal;
        position: absolute;
        color: #8D8D8D;
        top: 80px;
        left: 95px;
      }
    }

    .Evaluate {
      width: 95%;
      margin: 0 auto;
      line-height: 23px;
      font-size: 12px;
      background-color: #FFFFFF;
      height: auto;
      margin-bottom: 10px;
      box-shadow: 0px 2px 7px 0px rgba(159, 159, 159, 0.5);
      position: relative;
      color: #bbbbbb;
      text-indent: 1rem;
      padding-bottom: 8px;

      p {
        background-color: #f0f0f0;
        text-indent: 0;
        width: 85%;
        margin: 0 auto;
        margin-top: 5px;
        padding-left: 10px;
        padding-right: 10px;
      }
    }
  }

  .critique {
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    background-color: #f0f0f0;

    .block {
      box-shadow: 0px 2px 7px 0px rgba(159, 159, 159, 0.5);
      width: 94%;
      min-height: 70px;
      background-color: #FFFFFF;
      margin: 0 auto;
      margin-bottom: 10px;
      font-size: 12px;
      color: #666666;
      position: relative;

      .time {
        width: 95%;
        margin: 0 auto;
        height: 30px;
        line-height: 30px;
      }

      .cout {
        width: 95%;
        margin: 0 auto;
        margin-bottom: 5px;
      }

      .close {
        width: 20px;
        height: 20px;
        background-image: url("../assets/image/user/close.png");
        background-size: 100% 100%;
        position: absolute;
        right: 10px;
        top: 10px;
      }
    }
  }


  .data::after {
    content: "";
    width: 46%;
  }
}
</style>
`
