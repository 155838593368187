import request from "../utils/request";



export const initwork = (query) => {
    return request({
        url: process.env.VUE_APP_URL + "user/init/work",
        method: "get",
        params: query,
    });
};

export const initcollect = (query) => {
    return request({
        url: process.env.VUE_APP_URL + "user/init/collect",
        method: "get",
        params: query,
    });
};

export const initreceive = (query) => {
    return request({
        url: process.env.VUE_APP_URL + "user/init/receive",
        method: "get",
        params: query,
    });
};


export const initcomment = (query) => {
    return request({
        url: process.env.VUE_APP_URL + "user/init/comment",
        method: "get",
        params: query,
    });
};


export const putavatar = (query) => {
    return request({
        url: process.env.VUE_APP_URL + "user/put/avatar",
        method: "post",
        data: query,
    });
};

export const rmcomment = (query) => {
    return request({
        url: process.env.VUE_APP_URL + "user/comment/delete",
        method: "post",
        data: query,
    });
};


export const base64Isv = (query) => {
    return request({
        url: process.env.VUE_APP_URL + "image/base64Isv",
        method: "post",
        data: query,
    });
};


export const evaluateMy = (query) => {
    return request({
        url: process.env.VUE_APP_URL + "evaluate/user/my",
        method: "get",
        params: query,
    });
};


export const evaluateOthers = (query) => {
    return request({
        url: process.env.VUE_APP_URL + "evaluate/user/others",
        method: "get",
        params: query,
    });
};